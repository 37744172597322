import {gotoUrl} from "./common_methods";

export class WxLogin {
  constructor(_at_self) {
    this.self = _at_self;
    this.modal = this.self;
    this.authorizeForms = this.modal.find('#sign-in-user, #sign-up-user');
    this.anchoreName = location.hash;
    this.init();
  }

  init() {
    this.modal.on('show.bs.modal', (e) => {
      if (e.relatedTarget === document.getElementById('sign_in_btn')) {
        $('#login-modal a[href="#sign-in-tab"], #sign-in-tab').addClass('active')
        $('#login-modal a[href="#sign-up-tab"], #sign-up-tab').removeClass('active')
      } else if (e.relatedTarget === document.getElementById('sign_up_btn')) {
        $('#login-modal a[href="#sign-up-tab"], #sign-up-tab').addClass('active')
        $('#login-modal a[href="#sign-in-tab"], #sign-in-tab').removeClass('active')
      }
    })

    this.modal.on('hide.bs.modal', (e) => {
      $('#sign-in-tab form').get(0).dispatchEvent(new Event('custom:clearFormErrors'));
      $('#sign-up-tab form').get(0).dispatchEvent(new Event('custom:clearFormErrors'));
    })
    this.authorizeForms.on('ajax:success', (function(_this) {
      return function(e) {
        return gotoUrl(location.origin, {
          action: 'replace'
        });
      };
    })(this));
    this.authorizeForms.on('ajax:error', (function(_this) {
      return function(e) {
        var errorText, response, status, xhr, _ref;
        _ref = e.detail, response = _ref[0], status = _ref[1], xhr = _ref[2];
        errorText = '';
        if (response.hasOwnProperty('errors')) {
          errorText = response.errors.full_messages.join(', ');
        } else {
          errorText = response.error;
        }
        $(e.currentTarget).find('.alert').text(errorText).removeClass('d-none');
      };
    })(this));
    $('form').on('ajax:error', (function(_this) {
      return function(e) {
        var response, status, xhr, _ref;
        _ref = e.detail, response = _ref[0], status = _ref[1], xhr = _ref[2];
        if (status === "Unauthorized") {
          _this.modal.modal('show');
        }
      };
    })(this));
    if (this.anchoreName === '#authorize') {
      this.modal.modal('show');
    }
  };

  currentFrame() {
    return document.getElementById('wx-authorize-frame');
  };
}