import {LnxTableLocalization} from "./lnx_table_localization";
import {throttle} from "./common_methods";
import 'datatables.net-bs4';

export class Lnxtable {
  constructor(_at_self) {
    this.self = _at_self;
    this.options = {
      'dom': 't<"tfooter d-flex justify-content-between align-items-center"ip>',
      'aaSorting': [],
      'language': new LnxTableLocalization().locale($('meta[name=language]').attr('content')),
      "stateSave": true,
      'stateSaveParams': this.fillSearch
    };
    this.searchField = $("input#search");
    this.tableApi = void 0;
    this.init();
  }

  init = function () {
    if ($.fn.DataTable.isDataTable(this.self)) {
      return;
    }
    this.tableApi = this.self.DataTable(this.options);
    this.searchField.on('input', (function (_this) {
      return function (e) {
        throttle(function () {
          return _this.tableApi.search(e.currentTarget.value).draw();
        });
      };
    })(this));
  };

  fillSearch = function (settings, data) {
    //HACK
    const searchField = $("input#search");
    if (!searchField.val()) {
      searchField.val(data.search.search);
    }
  };
}