import {cdek_plugin_city_list_request_path} from '../../routes'

export class YandexGeocoder {
    constructor(parent) {
        this.fieldSet = parent;
        this.fieldNames = ['postal_code', 'unparsed_address', 'flat'];
        this.validationErrorText = 'Error determining the address, please specify or indicate it on the map.';
        this.presicion = 0;
        this.selectOptins = {
            containerCss: {
                height: '2.3rem'
            },
            language: $('meta[name=language]').attr('content'),
            minimumInputLength: 1,
            width: 'resolve'
        };

        this.init();
    }

    init(){
        return this.fieldSet.on("click", "button.confirm-option", function() {
            var component, input, s_element;
            s_element = $(this).closest(".suggestion");
            component = s_element.attr("data-component");
            input = s_element.closest('form').find("input[data-component='" + component + "'],textarea[data-component='" + component + "']");
            input.val(s_element.data('option'));
            input.removeClass('is-invalid');
            s_element.hide();
        });
    }
}
