export class YandexMap {
  constructor (base) {
    this.base = base;
    this.loader = $('#map-loader');
    this.searchField = $('#map-search');
    this.helpText = $('#map-search-help');
    this.defaults = {
      center: [55.76, 37.64],
      zoom: 12,
      controls: ['fullscreenControl']
    };
    this.map = void 0;
    this.geo = void 0;
    this.sug = void 0;
    this.fieldSetObj = void 0;
    ymaps.ready((function (_this) {
      return function () {
        return _this.init();
      };
    })(this));
  }

  init = function () {
    this.map = new ymaps.Map(this.base.mapId, this.defaults);
    this.sug = new ymaps.SuggestView(this.searchField[0]);
    this.sug.events.add('select', (function (_this) {
      return function (e) {
        _this.geocode(e.get('item').value, function (obj) {
          return _this.newMarker(obj, true);
        });
      };
    })(this));
    this.map.events.add('click', (function (_this) {
      return function (e) {
        _this.searchField.val('');
        _this.point = e.get('coords');
        _this.geocode(_this.point, (function (_this) {
          return function (obj) {
            return _this.newMarker(obj);
          };
        })(_this));
      };
    })(this));
  };

  callback = function(_arg, addrComps) {
    var lat, lng;
    lat = _arg[0], lng = _arg[1];
    const address = this.getAddressString(addrComps);
    this.geocode(address, (function (_this) {
      return function (obj) {
        const postal_code = obj.properties.get('metaDataProperty.GeocoderMetaData.Address.postal_code');
        _this.base.fieldSet.find("input[data-component='postal_code']").val(postal_code);
      };
    })(this));
    this.base.latitudeInp.val(lat);
    this.base.longitudeInp.val(lng);
    this.base.modal.modal('hide');
  }

  getAddressString(addrComps) {
    const compOrder = ['country', 'province', 'locality', 'district', 'street', 'house']
    return compOrder.map((comp) => {
      return addrComps[comp];
    }).filter(element => {
      return element !== undefined;
    }).join(', ').replace('Russian Federation', 'Russia')
  }

  purge = function () {
    this.map?.destroy();
    this.sug?.destroy();
    this.map = void 0;
    this.sug = void 0;
    this.helpText.text('');
  };

  clickHandler = function (e) {
    this.searchField.val('');
    this.point = e.get('coords');
    this.geocode(this.point, (function (_this) {
      return function (obj) {
        return _this.newMarker(obj);
      };
    })(this));
  };

  focusLocation = function () {
    var lat, lng, query, _ref;
    _ref = this.base.getPoint(), lat = _ref[0], lng = _ref[1];
    query = this.base.fieldSet.find("textarea[data-component='unparsed_address']").val()
    if (!!lat && !!lng) {
      this.point = [lat, lng];
      this.map.setCenter(this.point, 16);
      this.map.geoObjects.add(new ymaps.Placemark(this.point));
    } else if (!!query) {
      this.geocode(query, (function (_this) {
        return function (obj) {
          _this.point = obj.geometry.getCoordinates();
          _this.map.setCenter(_this.point, 16);
          return _this.map.geoObjects.add(new ymaps.Placemark(_this.point));
        };
      })(this));
    } else {
      return;
    }
  };

  newMarker = function (geoObj, setCenter) {
    var data;
    if (setCenter == null) {
      setCenter = false;
    }
    if (setCenter) {
      this.point = geoObj.geometry.getCoordinates();
    }
    data = geoObj.properties.get('metaDataProperty.GeocoderMetaData.Address');
    if (this.marker) {
      this.marker.properties.set('balloonContentBody', data['formatted']);
      this.marker.properties.set('balloonContentFooter', 'Применить');
      this.marker.geometry.setCoordinates(this.point);
    } else {
      this.marker = new ymaps.Placemark(this.point, {
        balloonContentBody: data['formatted'],
        balloonContentFooter: 'Применить'
      });
      this.map.geoObjects.add(this.marker);
    }
    if (setCenter) {
      this.map.setCenter(this.point, 16);
    }
    this.marker.balloon.events.add('click', (function (_this) {
      return function (e) {
        var addr, comps;
        comps = geoObj.properties.get('metaDataProperty.GeocoderMetaData.Address.Components');
        addr = {};
        $.each(comps, function (i, obj) {
          return addr[obj.kind] = obj.name;
        });
        _this.callback(_this.point, addr);
      };
    })(this));
    this.marker.balloon.open();
  };

  geocode = function (query, callback) {
    ymaps.geocode(query, {
      results: 1
    }).then((function (_this) {
      return function (res) {
        return callback(res.geoObjects.get(0));
      };
    })(this));
  };

  locateAddress = function (query) {
    this.helpText.text('');
    this.loader.show();
    this.geocode(query, (function (_this) {
      return function (obj) {
        var hint, precision;
        hint = void 0;
        if (obj) {
          precision = obj.properties.get('metaDataProperty.GeocoderMetaData.precision');
          hint = (function () {
            switch (precision) {
              case 'exact':
                return void 0;
              case 'number':
              case 'near':
              case 'range':
                return 'Inaccurate address, clarification required';
              case 'street':
                return 'Clarification required. Enter house number';
              default:
                return 'Inaccurate address, clarification required';
            }
          })();
          _this.map.setCenter(obj.geometry.getCoordinates(), 12);
        } else {
          hint = 'Address not found';
        }
        if (hint) {
          _this.helpText.text(hint);
        }
        _this.loader.hide();
      };
    })(this));
  };
}
