// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import {RemoteHelper} from "../src/remote_helper";

import('../src/core_extentions');

import Rails from "@rails/ujs"
import {YandexMap} from "../src/js_map/yandex_map";
import {confirmation_note_path, track_orders_path} from '../routes';

// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery-ui"
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/index'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover'
import 'bootstrap/js/dist/scrollspy'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/toast'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/util'
import 'jquery-mask-plugin'
global.Rails = Rails;
global.YandexMap = YandexMap;
Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import $ from 'jquery';
global.$ = jQuery;
import 'select2'
$.fn.select2.amd.define('select2/i18n/ru',[],require("select2/src/js/select2/i18n/ru"));
// Add DataTables jQuery plugin

import {YandexGeocoder} from "../src/geocoder/yandex_geocoder";
import {WxLogin} from "../src/wx_login";
import {NumericInput} from "../src/numeric_Input";
import {JsMap} from "../src/js_map";
import {Lnxtable} from "../src/lnx_table";
import {gotoUrl} from "../src/common_methods";
(function() {
    $.fn.select2.defaults.set("theme", "bootstrap");

}).call(this);

console.log('TEST')

var maskList = {
    phoneRu: [
        '+7(000)000-00-00', {
            placeholder: '+7(___)___-__-__'
        }
    ],
    phoneUs: [
        '+1(000)000-00-00', {
            placeholder: '+1(___)___-__-__'
        }
    ],
    phoneZh: [
        '100-0000-0000', {
            placeholder: '1__-____-____'
        }
    ],
    passportSeries: [
        '00 00', {
            placeholder: '__ __'
        }
    ],
    passportNumber: [
        '000000', {
            placeholder: '______'
        }
    ],
    inn: [
        '000000000000', {
            placeholder: '____________'
        }
    ],
    email: '',
    url: [
        'https://Z', {
            placeholder: 'https://www.example.com',
            translation: {
                'Z': {
                    pattern: /[^\s]/,
                    recursive: true
                }
            }
        }
    ],
    sealNumber: [
        '0000000000', {
            placeholder: '__________'
        }
    ]
};

document.addEventListener('DOMContentLoaded', () => {
    $('#track-button').on('click', function() {
        var trackValue;
        trackValue = $('#track-input').val();
        gotoUrl(track_orders_path({
            number: trackValue
        }));
    });

    $(document).on('ajax:success', 'form#sign-up-user', function(e) {
        gotoUrl(confirmation_note_path())
    })

    $(document).on('ajax:success', 'form#reset-password', function(e) {
        $('form#reset-password').addClass('d-none')
        $('#reset-success').removeClass('d-none')
    })


    let options = {
        containerCss: {
            height: '2.3rem'
        },
        width: 'resolve',
        language: $('meta[name=language]').attr('content')
    };
    $('[data-autocomplete="select2"]').select2(options).on('select2:open', () => {
        // HACK
        document.querySelector('.select2-search__field').focus();
    })

    $('table.datatable').each(function (_index, el) {
        new Lnxtable($(el))
    })

    $('[data-provider]').each(function (_index, el) {
        new YandexGeocoder($(el))
    })
    $('[data-remote]').each(function (_index, el) {
        new RemoteHelper($(el))
    })

    $('[data-mask]').each(function (_index, el) {
        $(el).mask(...maskList[el.dataset.mask])
    })
    $('[data-initializer="jsmap"]').each(function (_index, el) {
        new JsMap($(el))
    })

    $('#login-modal').each(function (_index, el) {
        new WxLogin($(el))
    })

    $('input[data-currency]').each(function (_index, el) {
        new NumericInput($(el))
    })

    const $newApplicationForm = $('#new_customer_application')
    const $agreementModal = $('#agreement_modal')
    const $newApplicationModal = $('#new_application_modal')
    $("#success-alert").hide();

    $newApplicationForm.on('ajax:success', () => {
        $newApplicationModal.find("[data-dismiss=modal]").trigger('click');
        $("#success-alert").fadeTo(2000, 500).slideUp(500, function(){
            $("#success-alert").slideUp(500);
        });
    });
})